
body {
  /* background: black; */
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.swiper {
  margin-top: 150px;
  width: 100%;
  background-color:  black;
}
@media (max-width: 768px) {
  .swiper-slide img {
     margin-top: 55px;

  }
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: black;
 
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 400px;
  object-fit: cover;
  margin-bottom: 60px;
}
.item-name {
  font-family: 'Dancing script', cursive;
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  color: rgb(255, 255, 255);
  background-color: rgba(8, 7, 7, 0.7);
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
  padding: 5px 10px;
  border-radius: 5px;
  opacity: 0; /* Initially hidden */
  /* transition: opacity 0.3s ease; /* Add transition for smooth effect */
}
.swiper-slide:hover .item-name {
  opacity: 1; /* Show item name on hover */
}
.home-footer {
  background-color: #222222; /* Dark background */
  color: #fff; /* White text */
  padding: 20px; /* Add some padding */
  text-align: center; /* Center text by default */
}
.footer-row {
  display: flex;
  flex-wrap: wrap; /* Ensure wrapping for smaller screens */
  justify-content: center; /* Center content horizontally */
}
.footer-col {
  margin: 10px 0; /* Add some vertical spacing between rows */
}
#footer-style {
  margin: 5px 0; /* Add vertical spacing between footer elements */
  font-size: 14px; /* Adjust font size for clarity */
  display: flex; /* Flexbox for aligning icons and text */
  align-items: center; /* Vertically center text and icons */
  justify-content: center; /* Center-align content */
}
.footer-style svg {
  margin-left: 5px; /* Add spacing between icons */
  cursor: pointer; /* Make icons clickable */
  transition: color 0.3s; /* Add hover effect */
}
.footer-style svg:hover {
  color: #007bff; /* Change icon color on hover (blue) */
}

/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  #footer-style {
    font-size: 12px; /* Reduce font size on smaller screens */
  }
}

.mySwiper {
  margin-top: 120px;
}

.flex-container {
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

#description {
  font-family: 'Dancing script', cursive;
  padding-top: 50px;
  text-decoration-line: underline;
}

#ingredients {
  font-family: 'Dancing script', cursive;
  text-decoration-line: underline;
}
.details-btn{
  font-family: poppins;
}
.addToCart-btn{
  background-color: #C08552;
  color:#F3E9DC;
  border-color: #895737;
  font-family: poppins;
}
.addToCart-btn:hover{
  background-color: #5E3023;
  border-color: #895737;
}
.goBack-btn{
  background-color: #FBDFA2;
  border-color: #B78456;
  color:#5E3023;
  margin-bottom: 20px;
  font-family: poppins;
}
.goBack-btn:hover{
  background-color:#F8EDD6;
  border-color: #452216;
  color:#5E3023;
}
.sidebar {
  overflow-x: hidden;
  overflow-y: hidden;
}

#categories {
  font-family: 'Dancing script', cursive;
}

#items {
  font-family: poppins;
  font-size: 3vh;
  margin-left: 25px;
}

#linkItems {
  text-decoration-line: none;
  color: gray;
}

#popular-products {
  font-family: 'Dancing script', cursive;
}

#price-filter {
  font-family: 'Dancing script', cursive;
  margin-top: 50px;
}

#price {
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  font-size: 3vh;
  margin-left: 50px;
  color: gray;
}

#filter-btn:hover {
  background-color: black;
}

#sidebar-follow {
  font-family: 'Dancing script', cursive;
  margin-top: 50px;
}

#follow-icons {
  margin-left: 50px;
}

#tags {
  font-family: 'Dancing script', cursive;
  margin-left: 50px;
  margin-top: 50px;
}

#tag-links {
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  font-size: small;
  color: gray;

}

#tag-items {
  color: gray;
  text-decoration-line: none;
}

.cakename {
  margin-top: 50px;
  width: 500px;
}

.descrp {
  width: 475px;
}

.price-define {
  width: 480px
}

#footer-link {
  list-style: none;
}

#useful-links {
  color: grey;
  text-decoration-line: none;
  font-family: poppins;
  font-size: 3vh;
  line-height: 30px;
}

#footer-para {
  color: grey;
  font-family: poppins;
  font-size: 3vh;
}
.footer-container {
  width: 100vw;
  display: flex;
  flex-direction: row; /* Arrange items in a row (default) */
  justify-content: space-around; /* Distribute space between items */
  align-items: center; /* Align items vertically in the center */
  background-color: #ffffff; /* Optional: Adjust the background color */
  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.1); /* Add subtle shadow at the top */
  border-top: 1px solid #ccc; /* Divider line on top */
  position: relative; /* Ensure it doesn't get constrained by parent containers */
  left: 0;
  right: 0;
  margin: 0; /* Remove any extra margin */
  padding: 0; /* Remove any extra padding */
}

.child-one {
  margin-top: 100px;
}

#footer-icons {
  margin-top: 20px;
}
@media (max-width: 768px) {
  .footer-container {
      flex-direction: column; /* Stack items vertically */
      align-items: center; /* Center-align content */
  }

  .child-one, .childe-two, .child-three, .child-four {
      margin-bottom: 20px; /* Add spacing between sections */
      text-align: center; /* Center align text for smaller screens */
  }

  .child-four {
      width: 90%; /* Make the newsletter take most of the screen width */
  }

  #footer-icons {
      justify-content: center;
      margin-top: 10px;
  }
}

#order-pagination {
  margin-top: 150px;
  margin-left: 400px;
}

.blog {
  display: flex;
  flex-wrap: wrap;
  margin-top: 50px;
  /* justify-content: space-around; */
}

.left-part {
  flex-direction: column;
  margin-top: 150px;
  margin-left: 20px;
}

.right-part {
  /* margin-left: 100px; */
  margin-top: 200px;

}

#creation-para {
  text-align: center;
}

.carousel {
  margin-left: 50px;
  margin-top: 100px;
}

.border {
  border-style: hidden;
  width: 900px;
  height: auto;
  background-color: white;
  color: goldenrod;
  margin-left: 50px;
}
.responsive-text {
  font-family: "dancing of script";
  font-size: 8vh;
  margin-top: 40px;
}

@media (max-width: 2000px) {
  .responsive-text {
    font-size: 6vh;
    margin-top: 40px;
  }
  .border {
    border-style: hidden;
    width: 1000px;
    background-color: white;
    color: goldenrod;
    margin-left: 50px;
  }
}
@media (max-width: 1500px) {
  .responsive-text {
    font-size: 5vh;
    margin-top: 10px;
  }
  .border {
    border-style: hidden;
    width: 900px; 
    background-color: white;
    color: goldenrod;
    margin-left: 50px;
  }
}
@media (max-width: 1000px) {
  .responsive-text {
    font-size: 4vh;
    margin-top: 10px;
  }
  .border {
    border-style: hidden;
    width: 700px;
    background-color: white;
    color: goldenrod;
    margin-left: 50px;
  }
}
@media (max-width: 768px) {
  .responsive-text {
    font-size: 3vh;
    margin-top: 10px;
  }
  .border {
    border-style: hidden;
    width: 500px;
    background-color: white;
    color: goldenrod;
    margin-left: 50px;
  }
}
@media (max-width: 576px) {
  .responsive-text {
    font-size: 2vh;
    
  }
  .border {
    border-style: hidden;
    width: 300px;
    background-color: white;
    color: goldenrod;
    margin-left: 50px;
  } 
}
.cart {
  color: #5E3023;
  width: 100%;
}
.Check-head{
  color: #5E3023;
   width: 100%;
  display: flex;
  flex-direction: column;
}

#cart-heading, #checkout-heading {
  margin-top: 60px;
  padding-top: 25px;
  padding-bottom: 25px;
  margin-left: 100px;
  font-family: poppins;
  font-size: 10vh;
}
#cart-btn {
  background-color: whitesmoke;
  color: black;
  border-color: black;
  width: 220px;
  height: 50px;
  border-radius: 0%;
  margin-top: 20px;
  margin-bottom: 50px;
  font-family: 'Times New Roman';
  margin-left: 20px;
}

#checkout-btn {
  background-color: whitesmoke;
  color: black;
  border-color: black;
  width: 220px;
  height: 50px;
  border-radius: 0%;
  margin-left: 20px;
  font-family: 'Times New Roman';
}
#checkout-btn:hover, #cart-btn:hover {
  transform: scale(1.1);
}

#checkout-link {
  text-decoration-line: none;
  color: black;
}

#billing-heading {
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  font-size: 6vh;
}

#checkout {
  display: flex;
  flex-wrap: wrap; /* Allows sections to stack on smaller screens */
  gap: 20px;
}


.billing, .additional-info {
  /* flex-direction: column; */
  flex: 1; /* Take equal space */
  min-width: 300px;
}

#order-notes {
  font-family: 'Times';
  font-size: 3vh;
}

#payment-heading {
  background-color: #F3E9DC;
  color: #5E3023;
  width: 100%;
  margin-top: 60px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 100px;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
.payment {
  background-color: #E9EBEE;
  display:flex;
  justify-content: space-between;
  flex-direction: column;
}

#payment-details {
  font-family: 'Times';
  font-size: 5vh;
}

#payment-secure {
  font-family: 'Times';
  font-size: 3vh;
  color: #90949C;
}

#payment-button {
  background-color: #3b5999;
  border-color: #3b5999;
  border-radius: 3px;
  width: 450px;
  margin-left: 20px;
}
.payment-inputs{
  width: 100%;
  padding: 12px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
#debit-button {
  background-color: #90949C;
  border-radius: 3px;
  width: 450px;
  margin-left: 20px;
  border-color: #90949C;
}

#myorder-heading {
  margin-top: 100px;
  margin-left: 100px;
  font-family: 'Times New Roman';
  font-size: 10vh;
}

.my-order {
  background-color: #F3E9DC;
  color: #5E3023;
  width: 100%;
  /* margin-top: -190px;
  padding-top: 20px;
  padding-bottom: 20px; */
  margin-top: -40px;
  padding-top: 20px;
  padding-bottom: 20px;

}
#purchase-heading{
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif; 
  font-size: 6vh;
}
.order-link{
  text-decoration-line: none;
  font-size: 3vh;
}
@media (max-width: 400px) {
  #myorder-heading {
    margin-top: 250px;
    font-size: 5vh;
  } 
}
@media (max-width: 768px) {
  #myorder-heading {
    margin-top: 250px;
    font-size: 5vh;
  } 
  .my-order {
    margin-top: -190px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  #purchase-heading{ 
    font-size: 3vh;
  }
  #billing-heading {
    font-size: 3vh;
  }
  #payment-button{
    width: 100px;
  }
  #payment-details{
    font-size: 3vh;
  }
  #payment-secure{
    font-size: 2vh;
  }
  #debit-button{
    width: 300px;
  }
 

}

@media (max-width: 768px) {
  #cart-heading {
    margin-top: 60px;
    font-size: 10vh;
  }
 

}
/* General Table Styling */
#cart-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 16px;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Table Header Styling */
#cart-table thead {
  background-color: #f4f4f4;
  color: #333;
}

#cart-table th {
  padding: 12px;
  font-weight: bold;
  text-transform: uppercase;
  border-bottom: 2px solid #ddd;
}

/* Table Body Styling */
#cart-table td {
  padding: 12px;
  border-bottom: 1px solid #ddd;
  vertical-align: middle;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

#cart-table tr:hover {
  background-color: #f9f9f9;
}

/* Image Styling */
#cart-table img {
  border-radius: 4px;
  display: inline-block;
  vertical-align: middle;
}

/* Button Styling */
.btn-plus,
.btn-minus {
  padding: 5px 10px;
  font-size: 14px;
  font-weight: bold;
  color: #F3E9DC;
  background-color: #C08552;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-plus:hover,
.btn-minus:hover {
  background-color: #5E3023;
}

.swal-custom-button {
  background-color: #C08552 !important; /* Custom button background */
  color: #F3E9DC !important;            /* Text color */
  font-weight: bold !important;         /* Make the text bold */
  border: 2px solid #F3E9DC !important;           
  border-radius: 5px !important;        /* Rounded corners */
  padding: 10px 20px !important;
  border-color: #F3E9DC;
}

.swal-custom-button:hover {
  background-color: #5E3023 !important; /* Darker shade on hover */
      /* Border color on hover */
}

/* General Styles */
.checkout-container {
  display: flex;
  flex-direction: column;
  gap: 20px; /* Space between sections */
  padding: 20px;
}

.checkout-header h1 {
  text-align: center;
  font-size: 1.8rem;
}

.checkout-sections {
  display: flex;
  flex-wrap: wrap; /* Allows sections to stack on smaller screens */
  gap: 20px;
}

/* Billing Details and Additional Info */
.billing-details,
.additional-info {
  flex: 1; /* Take equal space */
  min-width: 300px; /* Ensures responsiveness */
}

h6 {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.error-text {
  color: red;
  font-size: 0.9rem;
  margin: 5px 0 10px;
}
.textfield {
  margin-bottom: 100px;
}


/* Responsive Design */
@media (max-width: 768px) {
  .checkout-sections {
    flex-direction: column; /* Stack sections vertically */
  }

  .billing-details,
  .additional-info {
    min-width: 100%; /* Full width on small screens */
  }

  .order-summary button {
    width: 100%; /* Full width for the button */
  }
  #checkout-heading{
    font-size: 4vh;
  }
}

.not-found-container {
  text-align: center;
  padding: 50px 20px;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.not-found-title {
  padding-top: 20px;
  font-size: 120px;
  color: #222222;
  margin: 0;
}

.not-found-message {
  font-size: 24px;
  color: #5E3023;
  margin-top: 10px;
}

.not-found-link {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 18px;
  color: #F3E9DC;
  background-color: #C08552;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.not-found-link:hover {
  background-color: #452216;
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .not-found-title {
    font-size: 80px; /* Adjust font size for smaller screens */
  }

  .not-found-message {
    font-size: 20px; /* Adjust message size */
  }

  .not-found-link {
    font-size: 16px; /* Smaller button text */
    padding: 8px 16px; /* Adjust padding */
  }
}

@media screen and (max-width: 480px) {
  .not-found-title {
    font-size: 60px; /* Further reduce title size */
  }

  .not-found-message {
    font-size: 18px; /* Smaller message */
  }

  .not-found-link {
    font-size: 14px; /* Adjust button size */
    padding: 6px 12px;
  }
}







