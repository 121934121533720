 .container-fluid {
   width: 100%;
   background-color: black;
   overflow: hidden;
   margin: 0%;
   padding: 0%;
   min-height: 100vh;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
 }

 .container {
   width: 100%;
   margin: 0 auto;
 }

 #sidebar-fix {
   margin-top: 220px;
 }

 #footer {
   margin-top: 20px;
 }
